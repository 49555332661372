import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet-async";

import EbusinessCardBanner from '../../assets/img/ebc/ebc.png';

import EbusinessCardBlue from '../../assets/img/ebc/Blue.JPG';
import EbusinessCardPink from '../../assets/img/ebc/Pink.JPG';
import EbusinessCardDarkGreen from '../../assets/img/ebc/DarkGreen.JPG';

function EBusinessCardDemo() {
    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="businesscard"
        >
            {/* Meta Information */}
            <Helmet>
                <title>e-Business Card - TN76 Digital: Crafting Seamless Web Experiences</title>
                <meta
                    name="description"
                    content="Explore TN76's electronic business card with QR code and vCard download."
                />
            </Helmet>

            {/* Businesscard Section */}
            <section id="ebusinesscard" className="businesscard">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2>Electronic Business Card</h2>
                        <p>Select your theme</p>
                    </header>
                    <img src={EbusinessCardBanner} alt="Elecronic Business Card"  className="img-fluid" />
                    <h4>Description:</h4>
                    <p>
                        The Contact Card Generator is an innovative solution for individuals and businesses to create,
                        share, and download professional contact cards. Packed with features like QR code generation,
                        vCard downloads, theme customization, and seamless integration with social platforms, this
                        application is perfect for modern networking needs. Enhance your professional profile, promote
                        your brand, and ensure easy access to your contact information with a sleek and user-friendly
                        interface.
                    </p>

                    <h4>Features:</h4>
                    <ul>
                        <li>
                            <strong>Dynamic Contact Card:</strong>
                            <br />
                            Generate and customize digital contact cards with details like name, title, organization,
                            phone, email, address, and website.
                        </li>
                        <li>
                            <strong>Theme Customization:</strong>
                            <br />
                            Choose from multiple pre-designed themes (dark red, pink, kids, light green, dark green, and
                            more) to match personal or brand identity.
                        </li>
                        <li>
                            <strong>QR Code Integration:</strong>
                            <br />
                            Embed QR codes on the card that include:
                            <ul>
                                <li>Website link</li>
                                <li>Full vCard details for quick scanning and importing.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>vCard Download:</strong>
                            <br />
                            One-click download of a vCard file compatible with most contact management apps like Google
                            Contacts and Outlook.
                        </li>
                        <li>
                            <strong>Social Media Links:</strong>
                            <br />
                            Add clickable social media icons for platforms like LinkedIn, GitHub, WhatsApp, and others.
                        </li>
                        <li>
                            <strong>Responsive Design:</strong>
                            <br />
                            Optimized for desktop, tablet, and mobile use.
                        </li>
                    </ul>
                    <h4 name="ebc_samples">Electronic businessCardBanner Examples:</h4>
                    
                    <div className="row gy-4">
    {/* Example 1 */}
    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
        <div className="business-card-details">
            <Link to="/demo/e-business-card/theme-blue" target="_blank">
                <img src={EbusinessCardBlue} alt="Elecronic Business Card" className="img-fluid" />
            </Link>
            <div className="business-card-info">
                <h3>Elecronic Business Card</h3>
                <p>Blue</p>
                <Link to="/demo/e-business-card/theme-blue" className="read-more" target="_blank">
                    <span>View Details</span> <i className="bi bi-arrow-right"></i>
                </Link>
            </div>
        </div>
    </div>

    {/* Example 2 */}
    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
        <div className="business-card-details">
            <Link to="/demo/e-business-card/theme-pink" target="_blank">
                <img src={EbusinessCardPink} alt="Elecronic Business Card" className="img-fluid" />
            </Link>
            <div className="business-card-info">
                <h3>Elecronic Business Card</h3>
                <p>Pink</p>
                <Link to="/demo/e-business-card/theme-pink" className="read-more" target="_blank">
                    <span>View Details</span> <i className="bi bi-arrow-right"></i>
                </Link>
            </div>
        </div>
    </div>

    {/* Example 3 */}
    <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
        <div className="business-card-details">
            <Link to="/demo/e-business-card/theme-green-dark" target="_blank">
                <img src={EbusinessCardDarkGreen} alt="Elecronic Business Card" className="img-fluid" />
            </Link>
            <div className="business-card-info">
                <h3>Elecronic Business Card</h3>
                <p>Dark Green</p>
                <Link to="/demo/e-business-card/theme-green-dark" className="read-more" target="_blank">
                    <span>View Details</span> <i className="bi bi-arrow-right"></i>
                </Link>
            </div>
        </div>
    </div>
</div>

                </div>
            </section>
        </motion.div>
    );
}

export default EBusinessCardDemo;
