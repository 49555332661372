import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet-async";

import pricingFree from '../../assets/img/pricing-free.png';
import pricingStarter from '../../assets/img/pricing-starter.png';
import pricingBusiness from '../../assets/img/pricing-business.png';
import pricingUltimate from '../../assets/img/pricing-ultimate.png';

function Home() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="pricing"
        >

    {/* Dynamically set the meta description */ }
    <Helmet>
        <title>Pricing - TN76 Digital: Crafting Seamless Web Experiences.</title>
    </Helmet>

            {/* Start Pricing Section ======= */}
            <section id="pricing" className="pricing">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h2>Pricing</h2>
                        <p>Check our Pricing</p>
                    </header>

                    <div className="row gy-4" data-aos="fade-left">

                        <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                            <div className="box">
                                <h3 >Free Quote</h3>
                                <div className="price"><sup>$</sup>0<span> / mo</span></div>
                                <img src={pricingFree} className="img-fluid" alt="" />
                                <ul>
                                    <li>Web site app quote</li>
                                    <li>Revisions</li>
                                    <li className="na">Installation</li>
                                    <li className="na">Database design</li>
                                    <li className="na">Web Design</li>
                                </ul>
                                <Link to="/contactus" className="btn-buy">Contact Us</Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                            <div className="box">
                                <span className="featured">Featured</span>
                                <h3 >Software Installation</h3>
                                <img src={pricingStarter} className="img-fluid" alt="" />
                                <ul>
                                    <li>Software Upload</li>
                                    <li>Installation</li>
                                    <li>Database setup + migr</li>
                                    <li className="na">Upgrades</li>
                                    <li className="na">Bug fixes</li>
                                </ul>
                                <Link to="/contactus" className="btn-buy">Contact Us</Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                            <div className="box">
                                <h3 >Website/ app Creation</h3>
                                <img src={pricingBusiness} className="img-fluid" alt="" />
                                <ul>
                                    <li>Home page</li>
                                    <li>3 pages</li>
                                    <li>Contact form</li>
                                    <li>10 revisions</li>
                                    <li>Installation</li>
                                </ul>
                                <Link to="/contactus" className="btn-buy">Contact Us</Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                            <div className="box">
                                <h3 >Full Stack service</h3>
                                <img src={pricingUltimate} className="img-fluid" alt="" />
                                <ul>
                                    <li>Website/ app Creation</li>
                                    <li>Hosting/Cloud/ installation</li>
                                    <li>Unlimited upgrades</li>
                                    <li>Unlimited updates</li>
                                    <li>Unlimited revisions</li>
                                </ul>
                                <Link to="/contactus" className="btn-buy">Contact Us</Link>
                            </div>
                        </div>

                    </div>

                </div>

            </section>


            {/* End Pricing Section ======= */}


        </motion.div>

    );
}

export default Home;
